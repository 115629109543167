import { Formik } from "formik"
import { navigate } from "gatsby-link"
import React from "react"
import * as Yup from "yup"

import { cleanQueryParam } from "../../../auth/utils"
import { Button, LabeledLink, Password } from "../../../components"
import { getUrlSearchParams } from "../../../utils/navigation"
import { useResetPassword } from "../../hooks"
import { validateNewPassword } from "../../validationObjects"

const validationSchema = Yup.object().shape(
  {
    password: validateNewPassword,
    confirmPassword: Yup.string().when("password", {
      is: (password: string) => !password || password.length === 0,
      then: Yup.string().max(
        0,
        "Please enter your password above before confirming it here"
      ),
      otherwise: Yup.string()
        .required("Confirming password required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
  },
  [["password", "confirmPassword"]]
)

const ResetPasswordForm: React.FC = () => {
  const resetPassword = useResetPassword()
  const queryParams = getUrlSearchParams()

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const payload = {
          userId: queryParams.uid,
          token: cleanQueryParam(queryParams.token),
          password: values.password,
        }
        try {
          const success = await resetPassword(payload)
          if (success) {
            navigate("/sign-in/")
          } else {
            navigate("/forgot-password/")
          }
        } catch (err) {
          console.warn(err)
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Password placeholder="Password *" name="password" />
          <Password placeholder="Confirm password *" name="confirmPassword" />
          <Button
            label="Reset password"
            disabled={isSubmitting}
            loading={isSubmitting}
            type="submit"
            color="#018567"
            textColor="white"
          />
          <LabeledLink
            label="Dont have an account?"
            linkText="Sign up"
            linkPath="/sign-up/"
          />
        </form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
