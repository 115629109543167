import { navigate } from "gatsby"
import React, { useEffect } from "react"

import { useNotify } from "../alert/hooks"
import AuthFormWrapper from "../auth/components/AuthFormWrapper"
import ResetPasswordForm from "../auth/reset-password/components/ResetPasswordForm"
import Seo from "../components/seo"
import { getUrlSearchParams } from "../utils/navigation"

const ResetPasswordPage: React.FC = () => {
  const isBrowser = typeof window !== "undefined"
  const notify = useNotify()
  const queryParams = getUrlSearchParams()
  const uid = queryParams.uid

  useEffect(() => {
    if (!uid) {
      notify(
        "warning",
        "Invalid Reset Password URL",
        "please contact Yoco support"
      )
      if (isBrowser) {
        navigate("/sign-in/")
      }
    }
  }, [isBrowser, notify, uid])

  return (
    <AuthFormWrapper title="Reset your password">
      <Seo title="Yoco - Forgot password" />
      <ResetPasswordForm />
    </AuthFormWrapper>
  )
}

export default ResetPasswordPage
